/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
  --background-color: #ffffff;
  /* Background color for the entire website, including individual sections */
  --default-color: #364d59;
  /* Default color used for the majority of the text content across the entire website */
  --heading-color: #52565e;
  /* Color for headings, subheadings and title throughout the website */
  --accent-color: #F68627;
  /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
  --surface-color: #ffffff;
  /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
  --contrast-color: #ffffff;
  /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
  --background-color: #f4f7f6;
  --surface-color: #ffffff;
}

.dark-background {
  --background-color: #060606;
  --default-color: #ffffff;
  --heading-color: #ffffff;
  --surface-color: #252525;
  --contrast-color: #ffffff;
}

/*--------------------------------------------------------------
# Blog Posts Section
--------------------------------------------------------------*/
.blog-posts article {
  background-color: var(--surface-color);
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
}

.blog-posts .post-img img {
  transition: 0.5s;
}

.blog-posts .post-date {
  background-color: var(--accent-color);
  color: var(--contrast-color);
  position: absolute;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 500;
}

.blog-posts .post-content {
  padding: 30px;
}

.blog-posts .post-title {
  font-size: 20px;
  color: var(--heading-color);
  font-weight: 700;
  transition: 0.3s;
  margin-bottom: 15px;
}

.blog-posts .meta i {
  font-size: 16px;
  color: var(--accent-color);
}

.blog-posts .meta span {
  font-size: 15px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-posts p {
  margin-top: 20px;
}

.blog-posts hr {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  margin-bottom: 15px;
}

.blog-posts .readmore {
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 1;
  transition: 0.3s;
  color: color-mix(in srgb, var(--heading-color), transparent 20%);
}

.blog-posts .readmore i {
  line-height: 0;
  transition: color 0.5s ease, transform 0.7s ease-in-out;
  margin-left: 6px;
  font-size: 16px;
}

.blog-posts article:hover .post-title {
  color: var(--accent-color);
}

.readmore:hover i {
  margin-left: 9px;
  color: var(--accent-color);
}

.blog-posts article:hover .post-img img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Blog Details Section
--------------------------------------------------------------*/
.blog-details {
  padding-bottom: 30px;
}

.blog-details .article {
  background-color: var(--surface-color);
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog-details .post-img {
  margin: -30px -30px 20px -30px;
  overflow: hidden;
}

.blog-details .title {
  color: var(--heading-color);
  font-size: 28px;
  font-weight: 700;
  padding: 0;
  margin: 30px 0;
}

.blog-details .content {
  margin-top: 20px;
}

.blog-details .content h3 {
  font-size: 22px;
  margin-top: 30px;
  font-weight: bold;
}

.blog-details .content blockquote {
  overflow: hidden;
  background-color: color-mix(in srgb, var(--default-color), transparent 95%);
  padding: 60px;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.blog-details .content blockquote p {
  color: var(--default-color);
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 500;
  font-size: 22px;
}

.blog-details .content blockquote:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--accent-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-details .meta-top {
  margin-top: 20px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
}

.blog-details .meta-top ul li+li {
  padding-left: 20px;
}

.blog-details .meta-top i {
  font-size: 16px;
  margin-right: 8px;
  line-height: 0;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.blog-details .meta-top a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  font-size: 14px;
  display: inline-block;
  line-height: 1;
}

.blog-details .meta-bottom {
  padding-top: 10px;
  border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.blog-details .meta-bottom i {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  display: inline;
}

.blog-details .meta-bottom a {
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  transition: 0.3s;
}

.blog-details .meta-bottom a:hover {
  color: var(--accent-color);
}

.blog-details .meta-bottom .cats {
  list-style: none;
  display: inline;
  padding: 0 20px 0 0;
  font-size: 14px;
}

.blog-details .meta-bottom .cats li {
  display: inline-block;
}

.blog-details .meta-bottom .tags {
  list-style: none;
  display: inline;
  padding: 0;
  font-size: 14px;
}

.blog-details .meta-bottom .tags li {
  display: inline-block;
}

.blog-details .meta-bottom .tags li+li::before {
  padding-right: 6px;
  color: var(--default-color);
  content: ",";
}

.blog-details .meta-bottom .share {
  font-size: 16px;
}

.blog-details .meta-bottom .share i {
  padding-left: 5px;
}

/*--------------------------------------------------------------
# Blog Author Section
--------------------------------------------------------------*/
.blog-author {
  padding: 10px 0 40px 0;
}

.blog-author .author-container {
  background-color: var(--surface-color);
  padding: 20px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.blog-author img {
  max-width: 120px;
  margin-right: 20px;
}

.blog-author h4 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
  padding: 0;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-author .social-links {
  margin: 0 10px 10px 0;
}

.blog-author .social-links a {
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  margin-right: 5px;
}

.blog-author p {
  font-style: italic;
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Blog Comments Section
--------------------------------------------------------------*/
.blog-comments {
  padding: 10px 0;
}

.blog-comments .comments-count {
  font-weight: bold;
}

.blog-comments .comment {
  margin-top: 30px;
  position: relative;
}

.blog-comments .comment .comment-img {
  margin-right: 14px;
}

.blog-comments .comment .comment-img img {
  width: 60px;
}

.blog-comments .comment h5 {
  font-size: 16px;
  margin-bottom: 2px;
}

.blog-comments .comment h5 a {
  font-weight: bold;
  color: var(--default-color);
  transition: 0.3s;
}

.blog-comments .comment h5 a:hover {
  color: var(--accent-color);
}

.blog-comments .comment h5 .reply {
  padding-left: 10px;
  color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.blog-comments .comment h5 .reply i {
  font-size: 20px;
}

.blog-comments .comment time {
  display: block;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 40%);
  margin-bottom: 5px;
}

.blog-comments .comment.comment-reply {
  padding-left: 40px;
}

/*--------------------------------------------------------------
# Comment Form Section
--------------------------------------------------------------*/
.comment-form {
  padding-top: 10px;
}

.comment-form form {
  background-color: var(--surface-color);
  margin-top: 30px;
  padding: 30px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.comment-form form h4 {
  font-weight: bold;
  font-size: 22px;
}

.comment-form form p {
  font-size: 14px;
}

.comment-form form input {
  background-color: var(--surface-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  font-size: 14px;
  border-radius: 4px;
  padding: 10px 10px;
}

.comment-form form input:focus {
  color: var(--default-color);
  background-color: var(--surface-color);
  box-shadow: none;
  border-color: var(--accent-color);
}

.comment-form form input::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.comment-form form textarea {
  background-color: var(--surface-color);
  color: var(--default-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 14px;
  height: 120px;
}

.comment-form form textarea:focus {
  color: var(--default-color);
  box-shadow: none;
  border-color: var(--accent-color);
  background-color: var(--surface-color);
}

.comment-form form textarea::placeholder {
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.comment-form form .form-group {
  margin-bottom: 25px;
}

.comment-form form .btn-primary {
  border-radius: 4px;
  padding: 10px 20px;
  border: 0;
  background-color: var(--accent-color);
  color: var(--contrast-color);
}

.comment-form form .btn-primary:hover {
  color: var(--contrast-color);
  background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widgets-container {
  background-color: var(--surface-color);
  padding: 30px;
  margin: 60px 0 30px 0;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.widget-title {
  color: var(--heading-color);
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0 0 20px 0;
}

.widget-item {
  margin-bottom: 40px;
}

.widget-item:last-child {
  margin-bottom: 0;
}

.search-widget form {
  background: var(--background-color);
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
  padding: 3px 10px;
  position: relative;
  transition: 0.3s;
}

.search-widget form input[type=text] {
  border: 0;
  padding: 4px;
  border-radius: 4px;
  width: calc(100% - 40px);
  background-color: var(--background-color);
  color: var(--default-color);
}

.search-widget form input[type=text]:focus {
  outline: none;
}

.search-widget form button {
  background: var(--accent-color);
  color: var(--contrast-color);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  font-size: 16px;
  padding: 0 15px;
  margin: -1px;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  line-height: 0;
}

.search-widget form button i {
  line-height: 0;
}

.search-widget form button:hover {
  background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.search-widget form:is(:focus-within) {
  border-color: var(--accent-color);
}

.categories-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.categories-widget ul li {
  padding-bottom: 10px;
}

.categories-widget ul li:last-child {
  padding-bottom: 0;
}

.categories-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 20%);
  transition: 0.3s;
}

.categories-widget ul a:hover {
  color: var(--accent-color);
}

.categories-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
  font-size: 14px;
}

.recent-posts-widget .post-item {
  display: flex;
  margin-bottom: 15px;
}

.recent-posts-widget .post-item:last-child {
  margin-bottom: 0;
}

.recent-posts-widget .post-item img {
  width: 80px;
  margin-right: 15px;
}

.recent-posts-widget .post-item h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.recent-posts-widget .post-item h4 a {
  color: var(--default-color);
  transition: 0.3s;
}

.recent-posts-widget .post-item h4 a:hover {
  color: var(--accent-color);
}

.recent-posts-widget .post-item time {
  display: block;
  font-style: italic;
  font-size: 14px;
  color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.tags-widget {
  margin-bottom: -10px;
}

.tags-widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tags-widget ul li {
  display: inline-block;
}

.tags-widget ul a {
  color: color-mix(in srgb, var(--default-color), transparent 30%);
  font-size: 14px;
  padding: 6px 14px;
  margin: 0 6px 8px 0;
  border: 1px solid color-mix(in srgb, var(--default-color), transparent 60%);
  display: inline-block;
  transition: 0.3s;
}

.tags-widget ul a:hover {
  background: var(--accent-color);
  color: var(--contrast-color);
  border: 1px solid var(--accent-color);
}

.tags-widget ul a span {
  padding-left: 5px;
  color: color-mix(in srgb, var(--default-color), transparent 60%);
  font-size: 14px;
}