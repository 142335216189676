/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 60px 0;
    overflow: hidden;
}

.activities-details-title {
    padding-bottom: 40px;
}

.activities-details-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
}

.activities-details-title h2::after {
    content: "";
    width: 120px;
    height: 1.2px;
    display: inline-block;
    background: #fd9042;
    margin: 4px 10px;
}

.activities-details-title p::after {
    content: "";
    width: 70px;
    height: 4px;
    display: block;
    background: #fd9042;
}

.activities-details-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    color: #2a2c39;
}

/*--------------------------------------------------------------
# activities-archives
--------------------------------------------------------------*/
.activities-archives {
    margin: 0;
    padding: 0;
    height: 18vh;
}

@media (max-width: 768px) {
    .activities-archives {
        height: 20vh;
    }
}

/*--------------------------------------------------------------
# activities-details
--------------------------------------------------------------*/
.activities-details {
    padding-top: 80px;
}

.activities-details .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.activities-details .content ul {
    list-style: none;
    padding: 0;
}

.activities-details .content ul li {
    padding-left: 28px;
    position: relative;
}

.activities-details .content ul li+li {
    margin-top: 10px;
}

.activities-details .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #e57f19;
    line-height: 1;
}

.activities-details .content p:last-child {
    margin-bottom: 0;
}

.activities-details .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #e57f19;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #e57f19;
}

.activities-details .content .btn-learn-more:hover {
    background: #e57f19;
    color: #fff;
    text-decoration: none;
}

/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .nav-tabs {
    border: 0;
}

.features .nav-link {
    border: 1px solid #d4d6df;
    padding: 15px;
    transition: 0.3s;
    color: #2a2c39;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.features .nav-link i {
    padding-right: 15px;
    font-size: 48px;
}

.features .nav-link h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.features .nav-link:hover {
    color: #e57f19;
}

.features .nav-link.active {
    background: #e57f19;
    color: #fff;
    border-color: #e57f19;
}

@media (max-width: 768px) {
    .features .nav-link i {
        padding: 0;
        line-height: 1;
        font-size: 36px;
    }
}

@media (max-width: 575px) {
    .features .nav-link {
        padding: 15px;
    }

    .features .nav-link i {
        font-size: 24px;
    }
}

.features .tab-content {
    margin-top: 30px;
}

.features .tab-pane h3 {
    font-weight: 600;
    font-size: 26px;
}

.features .tab-pane ul {
    list-style: none;
    padding: 0;
}

.features .tab-pane ul li {
    padding-bottom: 10px;
}

.features .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #e57f19;
}

.features .tab-pane p:last-child {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# archive
--------------------------------------------------------------*/
.section-bg {
    padding: 120px 0;
    color: #fff;
}


.archive .container {
    position: relative;
    z-index: 10;
}

.archive .content {
    padding: 30px 30px 30px 0;
}

.archive .content h3 {
    font-weight: 700;
    font-size: 34px;
    margin-bottom: 30px;
}

.archive .content p {
    margin-bottom: 30px;
}

.archive .content .archive-btn {
    padding: 8px 30px 9px 30px;
    color: #fff;
    border-radius: 50px;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    border: 2px solid #e57f19;
}

.archive .content .archive-btn i {
    font-size: 16px;
    padding-left: 5px;
}

.archive .content .archive-btn:hover {
    background: #e57f19;
}

.archive .icon-boxes .icon-box {
    margin-top: 30px;
}

.archive .icon-boxes .icon-box i {
    font-size: 40px;
    color: #e57f19;
    margin-bottom: 10px;
}

.archive .icon-boxes .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
}

.archive .icon-boxes .icon-box p {
    font-size: 15px;
    color: #848484;
}

@media (max-width: 1200px) {
    .archive .content {
        padding-right: 0;
    }
}

/* @media (max-width: 768px) {
    .archive {
        text-align: center;
    }
} */

/*--------------------------------------------------------------
# museum Section
--------------------------------------------------------------*/
#museum {
    width: 100%;
    height: 90vh;
    background-size: cover;
    position: relative;
    padding-top: 82px;
}

#museum:before {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#museum h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    color: #fff;
}

#museum h2 {
    color: #fff;
    margin: 10px 0 0 0;
}

#museum .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 4px;
    transition: 0.5s;
    margin-top: 30px;
    color: #fff;
    background: #e57f19;
    border: 2px solid #e57f19;
}

#museum .btn-get-started:hover {
    background: transparent;
    border-color: #fff;
}

@media (max-width: 768px) {
    #museum {
        /* text-align: center; */
        padding-top: 58px;
    }

    #museum h1 {
        font-size: 28px;
    }

    #museum h2 {
        font-size: 18px;
        line-height: 24px;
    }
}

@media (max-height: 500px) {
    #museum {
        height: 120vh;
    }
}




/*--------------------------------------------------------------
# MUSIC SCHOOL
--------------------------------------------------------------*/
#music-school .icon-box {
    padding: 50px 30px;
    transition: all ease-in-out 0.3s;
}

#music-school .icon-box:hover {
    padding: 30px 30px 70px 30px;
}

/*--------------------------------------------------------------
# CULTURAL ACTIVITIES
--------------------------------------------------------------*/
.cultural .cultural-list {
    padding: 0;
    list-style: none;
}

.cultural .cultural-list li {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
}

.cultural .cultural-list a {
    display: block;
    position: relative;
    font-family: #e57f19;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding-right: 25px;
    cursor: pointer;
}

.cultural .cultural-list i {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
}

.cultural .cultural-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
}

.cultural .cultural-list .icon-show {
    display: none;
}

.cultural .cultural-list a.collapsed {
    color: #343a40;
}

.cultural .cultural-list a.collapsed:hover {
    color: #e57f19;
}

.cultural .cultural-list a.collapsed .icon-show {
    display: inline-block;
}

.cultural .cultural-list a.collapsed .icon-close {
    display: none;
}

/*--------------------------------------------------------------
# Navigator
--------------------------------------------------------------*/
.navigator .icon-box {
    display: flex;
    align-items: center;
    padding: 14px 18px;
    background: #f5f5ff;
    transition: ease-in-out 0.3s;
    cursor: pointer;
}

.navigator .icon-box i {
    font-size: 32px;
    line-height: 1;
    transition: ease-in-out 0.3s;
}

.navigator .icon-box a {
    font-weight: 100;
    margin: 0;
    padding: 0;
    padding-left: 12px;
    line-height: 1;
    font-size: 16px;
}


.navigator .icon-box a {
    transition: ease-in-out 0.3s;
    color: #161616;
}

.navigator .icon-box-active i,
.navigator .icon-box:hover i,
.navigator .icon-box-active a,
.navigator .icon-box:hover a {
    color: #f5f5ff;
}

.navigator .icon-box-active,
.navigator .icon-box:hover {
    background: #e57f19;
    box-shadow: 3px 2px 4px 0 #3131315b;
}


.navigator ul li {
    list-style: none;
}

@media (max-width: 1024px) {
    .navigator .icon-box a {
        display: none;
    }
}

@media (max-width: 575px) {
    .navigator .icon-box a {
        display: block;
    }
}