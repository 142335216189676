@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  --color-default: #222222;
  --color-primary: #008374;
  --color-secondary: #f85a40;
}



body {
  font-family: "Poppins", sans-serif;
  color: #444444;
  box-sizing: border-box !important;
}

a {
  text-decoration: none;
  color: #F68627;
}

a:hover {
  color: #E96D00;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Satisfy", sans-serif;
}

* p.card-text {
  font-family: "Hind", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #848484;
}

.box a.read-more {
  font-size: 12px !important;
  font-weight: bold;
}

.bg-grey {
  background-color: #eaf1f0;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #F68627;
  width: 40px;
  height: 40px;
  border-radius: 7px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #E96D00;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# facebook link
--------------------------------------------------------------*/
.facebook-link {
  position: fixed;
  visibility: visible;
  left: -3px;
  bottom: 49%;
  z-index: 1000;
  background: #eaf1f0 !important;
  box-shadow: 0 0 4px 2px #3131315b;
  width: 43px;
  height: 40px;
  border-radius: 7px;
}

.facebook-link i {
  font-size: 30px;
  color: #002fff !important;
  line-height: 0;
}


/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
  padding: 0;
  font-size: 15px;
  height: 50px;
  transition: all 0.5s;
  background: rgba(26, 24, 22, 0.8);
  z-index: 996;
  color: rgba(255, 255, 255, 0.7);
}

#topbar.topbar-transparent {
  background: transparent;
}

#topbar.topbar-scrolled {
  top: -50px;
}

#topbar i {
  color: #E96D00;
  line-height: 0;
}

#topbar i span {
  color: #fff;
  font-style: normal;
  padding-left: 5px;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  top: 50px;
  height: 100px;
  transition: all 0.5s;
  padding: 45px 0;
  background: linear-gradient(#1a1816, #0e0d0c, #0e0d0c);
}

/* #header.header-transparent {
  background: transparent;
} */

#header.header-scrolled {
  top: 0;
  background: #1a1816d9;
}

#header .logo img {
  max-height: 65px;
}

@media (max-width: 991px) {
  #header .logo img {
    max-height: 60px;
  }
}

@media (max-width: 768px) {
  #header .logo img {
    max-height: 50px;
  }
}

/*--------------------------------------------------------------
# Book a table button Menu
--------------------------------------------------------------*/
.book-a-table-btn {
  background: #E96D00;
  color: #fff;
  border-radius: 7px;
  margin: 0 0 0 20px;
  padding: 10px 25px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
  white-space: nowrap;
}

.book-a-table-btn:hover {
  background: #E96D00;
  color: #fff;
}

@media (max-width: 992px) {
  .book-a-table-btn {
    margin: 0 15px 0 0;
    padding: 8px 20px;
    letter-spacing: 1px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.activities-navbar,
.navbar {
  padding: 0;
}

.activities-navbar ul,
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.activities-navbar li,
.navbar li {
  position: relative;
}

.activities-navbar a,
.activities-navbar a:focus,
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 24px;
  font-size: 15px;
  font-weight: 500;
  color: white;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #E96D00;
  cursor: pointer;
}

.navbar .dropdown ul {
  display: block;
  width: 75vw;
  position: absolute;
  left: -42vw;
  top: calc(100% + 30px);
  margin: 0;
  padding: 40px 30px;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  color: #433f39;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #E96D00;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(39, 37, 34, 0.9);
  transition: 0.3s;
  z-index: 0;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #433f39;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #E96D00;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #E96D00;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  overflow: hidden;
  margin-top: 6.05rem;
  padding: 0;
  background-color: #eaf1f0;
}

#hero .carousel-item {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(12, 11, 10, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .side-container,
#hero .carousel-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero .carousel-content {
  margin-left: -1.6rem;
  text-align: left;
}

#hero .side-content {
  text-align: center;
}



#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

#hero h2 span {
  color: #E96D00;
}

#hero p {
  width: 60%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 21px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.8);
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hero .carousel-control-next-icon:hover,
#hero .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: #E96D00;
}


#hero .carousel-indicators li {
  cursor: default;
}

.btn-send-email,
#hero .btn-menu,
#hero .btn-book {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 7px;
  transition: 0.5s;
  line-height: 1;
  margin: 0 10px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  color: #fff;
  border: 2px solid #E96D00;
}

.btn-send-email,
#hero .btn-menu:hover,
#hero .btn-book:hover {
  background: #E96D00;
  color: #fff;
}

@media (max-width: 768px) {
  #hero h2 {
    font-size: 28px;
  }

  #hero .carousel-content {
    margin-left: 0;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  #hero p {
    width: 50%;
  }

  #hero .carousel-content {
    margin-left: -2rem;
  }

  #hero .carousel-content p {
    width: 70%;
  }


  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

/*--------------------------------------------------------------
# ACTIVITIES SECTION
--------------------------------------------------------------*/
#activities {
  margin: 0;
  padding: 3rem 0;
}

.ftco-section .ftco-sub-title {
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  color: #d4d4d4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
}

.ftco-section .ftco-primary-title {
  margin-top: 0;
  margin-bottom: 30px;
}

.ftco-section {
  padding: 6em 0;
  position: relative;
}

@media (max-width: 767.98px) {
  .ftco-section {
    padding: 6em 0;
  }
}

.ftco-section {
  position: relative;
  width: 100%;
  display: block;
}

.activities .content ul i {
  font-size: 20px;
  color: #E96D00;
  position: absolute;
  margin-right: 2px;
  left: 0;
  top: 2px;
}

#library,
#archives,
#museum,
#music-school,
#sound-recording,
#cultural-activities {
  padding-bottom: 7rem;
}

/*--------------------------------------------------------------
# ARCHIVES
--------------------------------------------------------------*/


.activities h3 {
  font-weight: 300;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: var(--font-secondary);
}

.activities .call-us {
  left: 10%;
  right: 10%;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.08);
  padding: 20px;
  text-align: center;
}

.activities .call-us h4 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 5px;
}

.activities .call-us p {
  font-size: 28px;
  font-weight: 700;
  color: var(--color-primary);
}

.activities .content ul {
  list-style: none;
  padding: 0;
}

.activities .content ul li {
  padding: 0 0 10px 30px;
  position: relative;
}

.activities .content ul i {
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -3px;
  color: var(--color-primary);
}

.activities .content p:last-child {
  margin-bottom: 0;
}

.activities .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(var(--color-primary) 50%,
      rgba(0, 131, 116, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.activities .play-btn:before {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate-btn 2s;
  animation: pulsate-btn 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(0, 131, 116, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

.activities .play-btn:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.activities .play-btn:hover:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border: none;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 200;
  -webkit-animation: none;
  animation: none;
  border-radius: 0;
}

.activities .play-btn:hover:after {
  border-left: 15px solid var(--color-primary);
  transform: scale(20);
}

@-webkit-keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.activities .book-a-table-btn {
  background: #F68627;
  color: #fff;
  border-radius: 3px;
  margin-left: 2px;
  margin-bottom: 3px;
  padding: 3px 7px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 0.3s;
  white-space: nowrap;
}

.activities .book-a-table-btn:hover {
  background: #E96D00;
  color: #fff;
}

.activities .book-a-table-btn i {
  transition: all ease-in-out 0.3s;
}

.activities .book-a-table-btn:hover i {
  margin-left: 3.5px;
  color: #fff;
}

.activities .scroll-to-next {
  transition: .2s ease-in-out;
  background-color: #E96D00;
  padding: 7px;
  border-radius: 8px;
  color: #fff6e8;
}

.activities .scroll-to-next i {
  transition: .5s ease-in-out;
  font-size: 12px;
}


.activities .scroll-to-next:hover i {
  margin-left: 9px;
}

/*--------------------------------------------------------------
# ACTIVITIES UTILIS
--------------------------------------------------------------*/
.activities-archives {
  width: 100%;
  position: relative;
  margin-top: -4rem;
  /* background: var(--color-primary); */
}

@media (min-width: 1365px) {
  .activities-archives {
    background-attachment: fixed;
  }

  #activities .content-image {
    height: 100vh;
  }
}

.activities-archives h2 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #fff;
}

.activities-archives p {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  margin-bottom: 30px;
}

.activities-archives .btn-get-started {
  font-family: var(--font-primary);
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 14px 40px;
  border-radius: 50px;
  transition: 0.3s;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(255, 255, 255, 0.1);
}

.activities-archives .btn-get-started:hover {
  border-color: rgba(255, 255, 255, 0.5);
}

.activities-archives .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: #fff;
  font-weight: 600;
}

.activities-archives .btn-watch-video i {
  color: rgba(255, 255, 255, 0.5);
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}

.activities-archives .btn-watch-video:hover i {
  color: #fff;
}

@media (max-width: 640px) {
  .activities-archives h2 {
    font-size: 36px;
  }

  .activities-archives .btn-get-started,
  .activities-archives .btn-watch-video {
    font-size: 14px;
  }

  #activities .content-image {
    height: 40vh;
  }
}

@media (max-width: 992px) {
  #activities .content-image {
    height: 50vh;
  }

}

@media (max-width: 768px) {
  #activities .content-image {
    height: 45vh;
  }
}

.activities-archives .icon-boxes {
  margin-top: -7rem;
  z-index: 100;
  padding-bottom: 60px;
}

@media (min-width: 1200px) {
  .activities-archives .icon-boxes:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(50% + 20px);
    background-color: #fff;
  }
}

.activities-archives .icon-box {
  padding: 60px 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  height: 100%;
  width: 100%;
  text-align: center;
}

.activities-archives .icon-box .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.activities-archives .icon-box .title a {
  color: #5b5858;
  transition: 0.3s;
}

.activities-archives .icon-box .icon {
  margin-bottom: 20px;
  padding-top: 10px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  line-height: 1;
  color: #E96D00;
}

.activities-archives .icon-box:hover {
  background: #d8d6d6;
}

.activities-archives .icon-box:hover .title a,
.activities-archives .icon-box:hover .icon {
  color: #fff;
}

.list-section {
  color: #008374;
  background-color: #07212e;
}

.list-box .content span {
  display: block;
  line-height: 22px;
  font-size: 18px;
  margin-bottom: 4px;
}

.list-box .content p {
  margin-bottom: 0px;
  opacity: 1;
  color: #008374;
}

.list-box .list-icon i {
  display: block;
  font-size: 24px;
  margin-right: 15px;
  color: #f28123;
  width: 65px;
  height: 65px;
  text-align: center;
  line-height: 60px;
  border: 2px #f28123 dotted;
  border-radius: 999px;
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery {
  padding-top: 0;
}

.gallery .gallery-item {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gallery .gallery-item img {
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.4s;
}

.gallery .gallery-item:hover img {
  transform: scale(1.1);
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
  color: #5f5950;
}

.section-title h2 span {
  color: #E96D00;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 300;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumb-text p {
  color: #F28123;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 7px;
  width: 60%;
  margin: auto;
}

.breadcrumb-text h1 {
  font-size: 50px;
  font-weight: 900;
  color: #fff;
  margin: 0;
  margin-top: 20px;
}

.breadcrumb-section {
  padding: 150px 0;
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 1;
  background-attachment: fixed;
  padding-top: 200px;
}

.breadcrumb-section:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #07212e;
  z-index: -1;
  opacity: 0.8;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  width: 100%;
  overflow: hidden;
  margin-top: 6.05rem;
  padding: 0;
}

.about .container-fluid {
  padding: 0;
  margin: 0;
}

.about .container-fluid .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about .side-content {
  text-align: center;
}


.about h1 {
  font-size: 48px;
  font-weight: 700;
}

.about h2 {
  color: #0f0f0f;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

.about h2 span {
  color: #E96D00;
}

.about p {
  width: 60%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #1d1d1d;
}

.about h3 {
  font-size: 14px;
  font-weight: 700;
  color: #35322d;
  text-transform: uppercase;
}

.about h2 {
  font-size: 30px;
  font-weight: 700;
  color: #35322d;
}

.about p {
  margin: 15px 0 30px 0;
  line-height: 24px;
}

.about .btn-read-more {
  line-height: 0;
  padding: 15px 40px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #4154f1;
  box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
}

.about .btn-read-more span {
  font-family: "Nunito", sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}

.about .btn-read-more i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

.about .btn-read-more:hover i {
  transform: translateX(5px);
}

.about .cross-to-activities {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  top: 3px;
  right: 3px;
  color: #ffffff;
  margin-right: 7px;
  background-color: #185944;
  cursor: pointer;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}

.about .scroll-to-next:hover span {
  color: #185944;
}

.about .carousel-item {
  width: 100%;
  height: 77vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.about .carousel-item::before {
  content: "";
  background-color: rgba(12, 11, 10, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.about .carousel-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .carousel-content {
  margin-left: -1.6rem;
  text-align: left;
}

.about .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

.about .carousel-inner .carousel-item,
.about .carousel-inner .active.carousel-item-start,
.about .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

.about .carousel-inner .active,
.about .carousel-inner .carousel-item-next.carousel-item-start,
.about .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

.about .carousel-inner .carousel-item-next,
.about .carousel-inner .carousel-item-prev,
.about .carousel-inner .active.carousel-item-start,
.about .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

.about .carousel-control-next,
.about .carousel-control-prev {
  display: none;
}

.about .carousel-inner:hover .carousel-control-next,
.about .carousel-inner:hover .carousel-control-prev {
  display: inline-flex;
}

.about .carousel-control-next-icon,
.about .carousel-control-prev-icon {
  background: none;
  font-size: 21px;
  line-height: 0;
  width: auto;
  height: auto;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  transition: 0.3s;
  color: rgba(255, 255, 255, 0.8);
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about .carousel-control-next-icon:hover,
.about .carousel-control-prev-icon:hover {
  background: rgba(255, 255, 255, 0.3);
  color: #E96D00;
}


.about .carousel-indicators li {
  cursor: default;
}

@media (min-width: 1200px) {
  .about #heroCarousel ul {
    float: left;
    margin-left: -24rem;
  }

  .about #heroCarousel .carousel-item {
    height: 77vh;
  }
}

@media (max-width: 992px) {
  .about #heroCarousel ul {
    margin-left: -12rem;
  }

  .about #heroCarousel .carousel-item {
    height: 65vh;
  }


}

@media (max-width: 768px) {
  .about #heroCarousel ul {
    margin-left: -12rem;
  }

  .about #heroCarousel .carousel-item {
    height: 60vh;
  }
}


/* FEATURES */
.features .feature-icons h3 {
  color: #F68627;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;
  text-align: center;
}


.features .feature-icons .content .icon-box {
  display: flex;
}

.features .feature-icons .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 10px 0;
  color: #35322d;
}

.features .feature-icons .content .icon-box i {
  font-size: 44px;
  line-height: 44px;
  color: #35322d;
  margin-right: 15px;
}

.features .feature-icons .content .icon-box p {
  font-size: 15px;
  color: #848484;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.services .icon-box::before {
  content: "";
  position: absolute;
  background: #35322d;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}

.services .icon-box:hover::before {
  background: #35322d;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}

.services .icon {
  margin: 0 auto 20px auto;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #35322d;
  transition: all 0.3s ease-in-out;
}

.services .icon i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
}

.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #111;
}

.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}

.services .icon-box:hover .title a,
.services .icon-box:hover .description {
  color: #fff;
}

.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-box:hover .icon i {
  color: #35322d;
}

/*--------------------------------------------------------------
# COMPETENCE 
--------------------------------------------------------------*/
.competence .card-img-top {
  transition: all ease-in-out 0.4s;
}

.competence .card {
  overflow: hidden;
  transition: all ease-in-out 0.4s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0%;
}

.competence .card img {
  max-height: 13rem;
}

.competence .card-body .post-category {
  text-transform: uppercase;
  font-size: small;
  font-weight: 400;
}

.competence .card-body .post-date-card .content-date p {
  margin-top: -0.7rem;
  text-transform: uppercase;
}

.competence .card-body .post-date-card .content-date {
  z-index: 100;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #2b3a59;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #ffffff;
  right: 5px;
  top: -2px;
}

.competence .card-body .post-date-card {
  z-index: 100;
  border-radius: 12px;
  width: 70px;
  background-color: #2b3a59a7;
  padding-top: 3px;
  padding-bottom: 3px;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.4s;
  float: right;
  right: 12px;
  margin-top: -5.5rem;
}

.competence .card-body .post-date-card p {
  color: white;
  text-align: center;
}

.competence .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #F68627;
}

.competence .card-body h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 7px 0;
  color: #6c665c;
}

.competence .box p {
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.competence .card .box {
  transition: all ease-in-out 0.4s;
}

.competence .card:hover img {
  transform: scale(1.1);
}

/* --------------------------------------------------------------
# OUR BLOG
--------------------------------------------------------------*/
.our-blog .post-img {
  z-index: -100;
}

.our-blog .card {
  transition: all ease-in-out 0.4s;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0%;
}

.our-blog .card img {
  max-height: 13rem;
}

.our-blog .card-body .post-category {
  text-transform: uppercase;
  font-size: small;
  font-weight: 400;
  margin-top: 0.7rem;
}

.our-blog .card-body .post-date-card {
  z-index: 100;
  width: 100px;
  height: 35px;
  background-color: #2b3a59;
  border-radius: 7px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  float: right;
  margin-top: -1.3rem;
  transition: all ease-in-out 0.5s;
}

.our-blog .card-body .post-date-card p {
  color: white;
  text-align: center;
}

.our-blog .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #F68627;
}

.our-blog .card-body h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 7px 0;
  color: #6c665c;
}

.our-blog .box p {
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.our-blog .card .box {
  transition: all ease-in-out 0.4s;
}

.our-blog .card:hover .card-body .post-date-card {
  margin-top: -2.3rem;
}

.our-blog .card:hover .box {
  background: #E96D00;
  padding: 24px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.our-blog .card:hover .box span,
.our-blog .card:hover .box h4,
.our-blog .card:hover .box p {
  color: #fff;
}

/*--------------------------------------------------------------
# Menu Section
--------------------------------------------------------------*/
.menu #menu-flters {
  padding: 0;
  margin: 0 auto 0 auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}

.menu #menu-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px 10px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  border: 2px solid #E96D00;
  border-radius: 50px;
}

.menu #menu-flters li:hover,
.menu #menu-flters li.filter-active {
  color: #fff;
  background: #E96D00;
}

.menu #menu-flters li:last-child {
  margin-right: 0;
}

.menu .menu-content {
  margin-top: 30px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.menu .menu-content::after {
  content: "......................................................................"
    "...................................................................."
    "....................................................................";
  position: absolute;
  left: 20px;
  right: 0;
  top: -4px;
  z-index: 1;
  color: #dad8d4;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.menu .menu-content a {
  padding-right: 10px;
  background: #fff;
  position: relative;
  z-index: 3;
  font-weight: 700;
  color: #F68627;
}

.menu .menu-content span {
  background: #fff;
  position: relative;
  z-index: 3;
  padding: 0 10px;
  font-weight: 600;
}

.menu .menu-ingredients {
  font-style: italic;
  font-size: 14px;
  font-family: "Comic Neue", sans-serif;
  color: #948c81;
}

/*--------------------------------------------------------------
# Events
--------------------------------------------------------------*/
.events::before {
  content: "";
  background-color: rgba(12, 11, 10, 0.8);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.events .section-title h2 {
  color: #fff;
}

.events .container {
  position: relative;
}

@media (min-width: 1024px) {
  .events {
    background-attachment: fixed;
  }
}

.events .events-carousel {
  background: rgba(255, 255, 255, 0.08);
  padding: 30px;
}

.events .event-item {
  color: #fff;
}

.events .event-item h3 {
  font-weight: 600;
  font-size: 26px;
  color: #F68627;
}

.events .event-item .price {
  font-size: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-bottom: 15px;
}

.events .event-item .price span {
  border-bottom: 2px solid #F68627;
}

.events .event-item ul {
  list-style: none;
  padding: 0;
}

.events .event-item ul li {
  padding-bottom: 10px;
}

.events .event-item ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #F68627;
}

.events .event-item p:last-child {
  margin-bottom: 0;
}

.events .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.events .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}

.events .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #F68627;
}

/*--------------------------------------------------------------
# Specials
--------------------------------------------------------------*/
.specials {
  overflow: hidden;
}

.specials .nav-tabs {
  border: 0;
}

.specials .nav-link {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: #433f39;
  border-radius: 0;
  border-right: 2px solid #e8e7e4;
  font-weight: 600;
  font-size: 15px;
}

.specials .nav-link:hover {
  color: #E96D00;
}

.specials .nav-link.active {
  color: #E96D00;
  border-color: #E96D00;
}

.specials .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.specials .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #433f39;
}

.specials .details p {
  color: #777777;
}

.specials .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .specials .nav-link {
    border: 0;
    padding: 15px;
  }

  .specials .nav-link.active {
    color: #fff;
    background: #E96D00;
  }
}

/*--------------------------------------------------------------
# Book A Table
--------------------------------------------------------------*/
.book-a-table .php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}

.book-a-table .php-email-form .form-group {
  padding-bottom: 8px;
}

.book-a-table .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.book-a-table .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.book-a-table .php-email-form .error-message br+br {
  margin-top: 25px;
}

.book-a-table .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.book-a-table .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.book-a-table .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.book-a-table .php-email-form input,
.book-a-table .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.book-a-table .php-email-form input {
  height: 44px;
}

.book-a-table .php-email-form textarea {
  padding: 10px 12px;
}

.book-a-table .php-email-form button[type="submit"] {
  border: 0;
  background: #F68627;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}

.book-a-table .php-email-form button[type="submit"]:hover {
  background: #F68627;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-wrap {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.contact .info {
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #E96D00;
  float: left;
  width: 44px;
  height: 44px;
  background: #fff6e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #433f39;
  font-family: "Poppins", sans-serif;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #7a7368;
}

.contact .info:hover i {
  background: #E96D00;
  color: #fff;
}

.contact .php-email-form {
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .form-group {
  padding-bottom: 8px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact label {
  font-weight: 500;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

.contact .php-email-form button[type="submit"] {
  background: #F68627;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 12px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #E96D00;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Whu Us
--------------------------------------------------------------*/
.why-us .box {
  padding: 50px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 0.3s;
  height: 100%;
}

.why-us .box span {
  display: block;
  font-size: 28px;
  font-weight: 700;
  color: #F68627;
}

.why-us .box h4 {
  font-size: 24px;
  font-weight: 600;
  padding: 0;
  margin: 20px 0;
  color: #6c665c;
}

.why-us .box p {
  color: #aaaaaa;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

.why-us .box:hover {
  background: #E96D00;
  padding: 30px 30px 70px 30px;
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.18);
}

.why-us .box:hover span,
.why-us .box:hover h4,
.why-us .box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.footer {
  background: linear-gradient(#1a1816, #0e0d0c, #0e0d0c);
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

.footer .logo img {
  max-height: 120px;
}

@media (max-width: 991px) {
  .footer .logo img {
    max-height: 100px;
  }
}

@media (max-width: 768px) {
  .footer .logo img {
    max-height: 70px;
  }
}

.footer h3 {
  font-size: 36px;
  font-weight: 700;
  color: #F68627;
  position: relative;
  padding: 0;
  margin: 0 0 15px 0;
}

.footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
  color: rgba(255, 255, 255, 0.7);
}

.footer .social-links {
  margin: 0 0 40px 0;
}

.footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #46423b;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .social-links a:hover {
  color: #E96D00;
}

.footer .copyright {
  margin: 0 0 5px 0;
}

.footer .credits {
  font-size: 13px;
}

.footer .footer-links ul {
  list-style: none;
  padding: 0;
}

.footer .footer-links ul {
  padding-right: 2px;
  color: rgba(0, 131, 116, 0.8);
  font-size: 12px;
  line-height: 0;
}

.footer .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.footer .footer-links ul li:first-child {
  padding-top: 0;
}

.footer .footer-links ul a {
  color: rgba(255, 255, 255, 0.7);
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

.footer .footer-links ul a:hover {
  color: #fff;
  cursor: pointer;
}

.footer .footer-contact p {
  line-height: 26px;
}

.footer p.text-start {
  font-family: "Hind", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px !important;
}

.footer-links a {
  font-family: "Hind", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px !important;
}

.footer-contact p {
  font-family: "Hind", sans-serif;
  font-weight: 300;
  font-size: 16px !important;
  font-style: normal;
}

.single-footer-widget .nl-form {
  position: relative;
  z-index: 1;
  margin-top: 30px;
}

.single-footer-widget .nl-form input {
  width: 100%;
  height: 46px;
  background-color: #ffffff;
  border-radius: 30px;
  border: none;
  padding: 0 20px;
  color: #3e525f;
  font-size: 14px;
}

.single-footer-widget .nl-form input:focus {
  box-shadow: none;
}

.single-footer-widget .nl-form button {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  top: 3px;
  right: 3px;
  color: #ffffff;
  background-color: #F68627;
  cursor: pointer;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single-footer-widget .nl-form button:focus,
.single-footer-widget .nl-form button:hover {
  background: #E96D00;
}