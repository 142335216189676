/*--------------------------------------------------------------
# Specials
--------------------------------------------------------------*/
.specials .section-title h2 {
    color: #fff;
}

.specials .nav-tabs {
    border: 0;
}

.specials .nav-link {
    border: 0;
    padding: 12px 15px 12px 0;
    transition: 0.3s;
    color: #e8e7e4;
    border-radius: 0;
    border-right: 2px solid #e8e7e4;
    font-weight: 600;
    font-size: 15px;
}

.specials .nav-link:hover {
    color: #ffb03b;
    font-weight: 700;
}

.specials .nav-link.active {
    background-color: #0000004b;
    font-weight: 700;
    padding-left: 12px;
    color: #ffb03b;
    border-color: #ffb03b;
}

.specials .tab-pane.active {
    -webkit-animation: fadeIn 0.5s ease-out;
    animation: fadeIn 0.5s ease-out;
}

.specials .details .title {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #fff;
}

.specials .details p {
    color: #e8e7e4;
}

.specials .details p:last-child {
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .specials .nav-link {
        border: 0;
        padding: 15px;
    }

    .specials .nav-link.active {
        color: #fff;
        background: #ffb03b;
    }
}

.more-details {
    background: #e57f19;
    color: #fff;
    margin-top: 12px !important;
    border-radius: 3px;
    margin-left: 2px;
    margin-bottom: 3px;
    padding: 9px 12px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    transition: 0.3s;
    white-space: nowrap;
}

.more-details:hover {
    color: #000000af;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    padding: 120px 0;
    overflow: hidden;
    position: relative;
}

.cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
}

.cta p {
    color: #fff;
}

.cta .cta-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
    background: #009961;
    color: #fff;
    border-radius: 50px;
}

.cta .cta-btn:hover {
    background: #00b371;
}

@media (max-width: 1024px) {
    .cta {
        background-attachment: scroll;
    }
}

@media (min-width: 769px) {
    .cta .cta-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}