.about-service .content {
  background-color: #e57f19;
  padding: 40px;
}

.about-service h3 {
  font-size: 24px;
  font-weight: 700;
  color: #35322d;
}

.about-service p {
  margin: 15px 0 30px 0;
  line-height: 24px;
  font-size: 14px;
  color: #ffffff;
}

.what-we-do .icon-box {
  text-align: center;
  padding: 30px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
}

.what-we-do .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #eaf4fb;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.what-we-do .icon-box .icon i {
  color: #e57f19;
  font-size: 28px;
}

.what-we-do .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.what-we-do .icon-box h4 a {
  color: #35322d;
  transition: ease-in-out 0.3s;
}

.what-we-do .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.what-we-do .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
}

.what-we-do .icon-box:hover h4 a,
.what-we-do .icon-box:hover .icon i {
  color: #e57f19;
}

.title-box-d {
  padding-bottom: 1.8rem;
  margin-bottom: 1rem;
  position: relative;
}

.title-box-d .title-d {
  font-weight: 600;
  font-size: 2rem;
}

.title-box-d .title-d:after {
  content: "";
  position: absolute;
  width: 70px;
  height: 4px;
  background-color: #e57f19;
  bottom: 20px;
  left: 0;
}

.intro-single .title-single-box {
  padding: 1rem 0 1rem 2rem;
}

.intro-single .title-single-box {
  border-left: 3px solid #e57f19;
}

.intro-single .title-single-box .title-single {
  font-weight: 600;
  font-size: 2.2rem;
}

@media (min-width: 768px) {
  .intro-single .title-single-box .title-single {
      font-size: 2.1rem;
  }
}

@media (min-width: 992px) {
  .intro-single .title-single-box .title-single {
      font-size: 2.5rem;
  }
}

.intro-single .breadcrumb-box {
  padding: 1rem 0 0 0.5rem;
}

.intro-single .breadcrumb {
  background-color: transparent;
  padding-right: 0;
  padding-left: 0;
}

.section-about-service .title-vertical {
  position: absolute;
  letter-spacing: 0;
  transform: rotate(-90deg);
  top: 50px;
  text-align: left;
  font-size: 1.8rem;
  width: 300px;
  font-weight: 700;
  text-align: right;
}

.about-service-img-box {
  padding-left: 1rem;
  position: relative;
}

@media (min-width: 576px) {
  .about-service-img-box {
      padding-left: 4rem;
  }
}

@media (min-width: 768px) {
  .about-service-img-box {
      padding-left: 5rem;
  }
}

.sinse-box {
  background-color: #e57f19;
  max-width: 40vw;
  padding: 1rem 2rem;
  position: absolute;
  left: 15px;
  bottom: 10px;
}


@media (min-width: 576px) {
  .sinse-box {
      padding: 1.5rem 2.5rem;
      bottom: 20px;
  }
}

@media (min-width: 768px) {
  .sinse-box {
      padding: 1.5rem 3rem;
  }
}

@media (min-width: 992px) {
  .sinse-box {
      padding: 3rem 4.5rem;
  }
}

@media (max-width: 992px) {
  .sinse-box {
      margin: 0;
      margin-bottom: -5rem;
      left: 0;
      max-width: 100vw;
  }

  .section-t8 {
      padding-bottom: 2rem !important;
  }

}

.sinse-box .sinse-title {
  font-weight: 700;
  letter-spacing: 0.043em;
  font-size: 1rem;
}

@media (min-width: 576px) {
  .sinse-box .sinse-title {
      font-size: 1.3rem;
  }
}

@media (min-width: 768px) {
  .sinse-box .sinse-title {
      font-size: 1.8rem;
  }
}

@media (min-width: 992px) {
  .sinse-box .sinse-title {
      font-size: 2.2rem;
  }
}

.sinse-box .sinse-title span {
  position: absolute;
}

.sinse-box .sinse-title span:after {
  content: " ";
  width: 35px;
  height: 2px;
  background-color: #000000;
  position: absolute;
  bottom: -15px;
}

.sinse-box p {
  margin-bottom: 0;
  font-size: 0.7rem;
}

@media (min-width: 576px) {
  .sinse-box p {
      font-size: 0.8rem;
  }
}

@media (min-width: 576px) {
  .sinse-box p {
      font-size: 1rem;
  }
}

.sport-leisure {
  overflow: hidden;
}

.sport-leisure .nav-tabs {
  border: 0;
}

.sport-leisure .nav-link {
  border: 0;
  padding: 12px 15px 12px 0;
  transition: 0.3s;
  color: #433f39;
  border-radius: 0;
  border-right: 2px solid #e8e7e4;
  font-weight: 600;
  font-size: 15px;
}

.sport-leisure .nav-link:hover {
  color: #ffb03b;
}

.sport-leisure .nav-link.active {
  color: #ffb03b;
  border-color: #ffb03b;
}

.sport-leisure .tab-pane.active {
  -webkit-animation: fadeIn 0.5s ease-out;
  animation: fadeIn 0.5s ease-out;
}

.sport-leisure .details h3 {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #433f39;
}

.sport-leisure .details p {
  color: #777777;
}

.sport-leisure .details p:last-child {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .sport-leisure .nav-link {
    border: 0;
    padding: 15px;
  }

  .sport-leisure .nav-link.active {
    color: #fff;
    background: #ffb03b;
  }
}



