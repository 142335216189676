/*------/ Intro Single /------*/
/* .intro-single {
    padding: 12rem 0 3rem;
} */

/* @media (max-width: 767px) {
    .intro-single {
        padding-top: 9rem;
    }
} */

.title-box-d {
    padding-bottom: 1.8rem;
    margin-bottom: 1rem;
    position: relative;
}

.title-box-d .title-d {
    font-weight: 600;
    font-size: 2rem;
}

.title-box-d .title-d:after {
    content: "";
    position: absolute;
    width: 70px;
    height: 4px;
    background-color: #e57f19;
    bottom: 20px;
    left: 0;
}

.intro-single .title-single-box {
    padding: 1rem 0 1rem 2rem;
}

.intro-single .title-single-box {
    border-left: 3px solid #e57f19;
}

.intro-single .title-single-box .title-single {
    font-weight: 600;
    font-size: 2.2rem;
}

@media (min-width: 768px) {
    .intro-single .title-single-box .title-single {
        font-size: 2.1rem;
    }
}

@media (min-width: 992px) {
    .intro-single .title-single-box .title-single {
        font-size: 2.5rem;
    }
}

.intro-single .breadcrumb-box {
    padding: 1rem 0 0 0.5rem;
}

.intro-single .breadcrumb {
    background-color: transparent;
    padding-right: 0;
    padding-left: 0;
}

.section-about .title-vertical {
    position: absolute;
    letter-spacing: 0;
    transform: rotate(-90deg);
    top: 50px;
    text-align: left;
    font-size: 1.8rem;
    width: 300px;
    font-weight: 700;
    text-align: right;
}

.about-img-box {
    padding-left: 1rem;
    position: relative;
}

@media (min-width: 576px) {
    .about-img-box {
        padding-left: 4rem;
    }
}

@media (min-width: 768px) {
    .about-img-box {
        padding-left: 5rem;
    }
}

.sinse-box {
    background-color: #e57f19;
    max-width: 40vw;
    padding: 1rem 2rem;
    position: absolute;
    left: 15px;
    bottom: 10px;
}

.sinse-box p {
    margin-bottom: 0;
    font-size: 1rem !important;
}

@media (min-width: 576px) {
    .sinse-box {
        padding: 1.5rem 2.5rem;
        bottom: 20px;
    }
}

@media (min-width: 768px) {
    .sinse-box {
        padding: 1.5rem 3rem;
    }
}

@media (min-width: 992px) {
    .sinse-box {
        padding: 3rem 4.5rem;
    }
}

@media (max-width: 992px) {
    .sinse-box {
        margin: 0;
        margin-bottom: -5rem;
        left: 0;
        max-width: 100vw;
    }

    .section-t8 {
        padding-bottom: 18rem !important;
    }

    .sinse-box p {
        font-size: 0.825rem !important;
    }

}

.sinse-box .sinse-title {
    font-weight: 700;
    letter-spacing: 0.043em;
    font-size: 1.8rem !important;
}

@media (min-width: 576px) {
    .sinse-box .sinse-title {
        font-size: 2rem;
    }
}

@media (min-width: 768px) {
    .sinse-box .sinse-title {
        font-size: 2rem;
    }
}

@media (min-width: 992px) {
    .sinse-box .sinse-title {
        font-size: 2.2rem;
    }
}

.sinse-box .sinse-title span {
    position: absolute;
}

.sinse-box .sinse-title span:after {
    content: " ";
    width: 35px;
    height: 2px;
    background-color: #008374b3;
    position: absolute;
    bottom: -15px;
}

@media (min-width: 576px) {
    .sinse-box p {
        margin-bottom: 0;
    }
}