.navbar .dropdown ul {
    min-width: 63vw;
}

.navbar .nav-item {
    padding: 0.5rem 0xp;
}



.navbar .dropdown-hover:hover>.dropdown-menu {
    display: inline-block;
    min-width: 63vw;
    left: -25.4vw;
}
.navbar .dropdown-hover:hover>.dropdown-menu a {
    color: #141212ec;
}

.navbar .dropdown-hover:hover>.dropdown-menu a:hover {
    color: #e57f19;
    background-color: none!important;
}


.navbar .dropdown-hover>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}